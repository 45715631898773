<!--
 * @Author: wh
 * @Date: 2020-07-30 20:21:35
 * @LastEditTime: 2020-08-06 19:49:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\statisticalAnalysis\productionStatistics\default.vue
-->
<template>
    <div class="productionStatistics">
        <div class="warpper">
            <el-menu
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
            >
                <el-menu-item index="1">
                    场站
                </el-menu-item>
                <el-menu-item index="2">
                    产品标号
                </el-menu-item>
            </el-menu>
            <div class="main">
                <station v-if="activeIndex==='1'"></station>
                <productionNum v-else></productionNum>
            </div>
        </div>
    </div>
</template>

<script>
import station from './station';
import productionNum from './productionNum';
export default {
    components: {
        station, productionNum,
    },
    props: {},
    data() {
        return {
            activeIndex: '1',
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {},
    methods: {
        // tab切换
        handleSelect(key, keyPath) {
            this.activeIndex = key;
        },
    },
};
</script>
<style lang="stylus">
.productionStatistics
    height 100%
    .warpper
        height 100%
        background #fff
        .el-menu
            border-bottom none
            padding .2rem
            padding-top .1rem
            padding-bottom 0
            .el-menu-item
                padding 0 .01rem
                height .4rem
                line-height .4rem
                margin-right .2rem
                color #333333
                font-size .16rem
            .is-active
                color #D6000F
                border-color #D6000F
        .main
            height calc(100% - .5rem)
</style>